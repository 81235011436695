<template>
  <b-modal
    id="modal-add-advice"
    size="xl"
    centered
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @hidden="resetData"
    @ok="handleOk"
  >

    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Người tư vấn <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Người tư vấn"
              rules="required"
              :custom-messages="customCounselor"
            >
              <v-select
                v-model="employeeInfo.counselorId"
                :reduce="(name) => name.id"
                :class="{'is-invalid':errors.length > 0 }"
                label="name"
                :options="comboboxCounselor || []"
                placeholder="Người tư vấn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày tư vấn <span class="text-danger">(*)</span> </label>
            <validation-provider
              #default="{ errors }"
              name="Ngày tư vấn"
              rules="required"
              :custom-messages="customDate"
            >
              <date-picker
                v-model="employeeInfo.dateAdvise"
                :disabledInput="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Họ và tên</label>
            <b-form-input
              v-model="employeeInfo.name"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày sinh </label>
            <date-picker
              v-model="employeeInfo.birthDay"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Địa chỉ</label>
            <b-form-input v-model="employeeInfo.address" />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <small>Giới tính</small>
          <b-form-radio-group
            id="radio-group-1"
            v-model="employeeInfo.gender"
            class="mt-2"
            :options="options"
            name="radio-options"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Trình độ học vấn</label>
            <validation-provider
              #default="{ errors }"
              name="Trình độ học vấn"
              rules="required"
              :custom-messages="customLevel"
            >
              <v-select
                v-model="employeeInfo.academicLevel"
                :reduce="(value) => value.value"
                label="label"
                :class="{'is-invalid':errors.length > 0 }"
                :options="optionsSelect || []"
                placeholder="Trình độ học vấn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input v-model="employeeInfo.phoneNumber" />
          </b-form-group>
        </b-col>
        <b-col
          class="d-flex align-items-end"
          cols="12"
          md="4"
        >
          <b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="employeeInfo.isAdviseJob"
              name="checkbox-1"
            >
              Số người sử dụng lao động được tư vấn
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        label="Nội dung tư vấn"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="employeeInfo.content"
          no-resize
        />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BMedia,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BFormGroup,
  BBadge,
  BModal,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import Search from '@/components/search/Search.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BMedia,
    Search,
    BBadge,
    BModal,
    BButton,
    vSelect,
    BFormInput,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    comboboxCounselor: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default() {
        return {
          counselorId: '',
          dateAdvise: null,
          name: '',
          birthDay: null,
          address: null,
          gender: true,
          academicLevel: null,
          phoneNumber: null,
          isBussiness: false,
          content: null,
          isAdviseJob: false,
        }
      },
    },
  },
  data() {
    return {
      employeeInfo: JSON.parse(JSON.stringify(this.data)),
      customCounselor: {
        required: 'Người tư vấn là bắt buộc',
      },
      customLevel: {
        required: 'Trình độ học vấn là bắt buộc',
      },
      customDate: {
        required: 'Ngày tư vấn là bắt buộc',
      },
      options: [
        { text: 'Nam', value: true },
        { text: 'Nữ', value: false },
      ],
      optionsSelect: [
        {
          label: 'Đại học',
          value: 'University',
        },
        {
          label: 'Cao đẳng',
          value: 'College',
        },
        {
          label: 'Trung cấp',
          value: 'Intermediate',
        },
        {
          label: 'Sơ cấp nghề',
          value: 'Primary',
        },
        {
          label: 'Chưa qua đào tạo',
          value: 'None',
        },
      ],
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.employeeInfo = JSON.parse(JSON.stringify(val))
      },
    },
  },
  methods: {
    resetData() {
      this.employeeInfo = JSON.parse(JSON.stringify(this.data))
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$bvModal.hide('modal-add-advice')
          this.$emit('update', this.employeeInfo)
        }
      })
    },
  },
}
</script>
