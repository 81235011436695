<template>
  <div>
    <div class="page-container mb-2">
      <h4 class="mb-2">
        Bộ lọc
      </h4>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Năm"
            label-for="year"
          >
            <v-select
              :options="dataFilterYear"
              placeholder="Năm"
              @input="filterYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div>
      <my-tabs
        :listTabs="listTabs"
        :defaultActive="activeTab"
        typeCss="active-full"
        @setActive="setActive"
      />
      <div>
        <employee
          v-if="isRendered('employee')"
          ref="employee"
          class="tab-pane"
          :class="{ active: activeTab === 'employee' }"
          :year="year"
          :comboboxCounselor="comboboxCounselor"
          @getValiData="getValiData($event)"
        />
        <employer
          v-if="isRendered('employer')"
          ref="employer"
          class="tab-pane"
          :class="{ active: activeTab === 'employer' }"
          :year="year"
          :comboboxCounselor="comboboxCounselor"
          :comboboxBusiness="comboboxBusiness"
          @getValiData="getValiData($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol } from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Employee from './employee/List.vue'
import Employer from './employer/List.vue'
import store from '../store'

export default {
  components: {
    VSelect,
    BFormGroup,
    BRow,
    BCol,
    MyTabs,
    Employer,
    Employee,
  },
  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'employee',
          title: 'Hoạt động tư vấn việc làm người lao động',
          icon: 'UserIcon',
          isRendered: false,
        },
        {
          key: 'employer',
          title: 'Hoạt động tư vấn việc làm người sử dụng lao động',
          icon: 'UserIcon',
          isRendered: false,
        },
      ],
      dataFilterYear: [],
      comboboxCounselor: [],
      comboboxBusiness: [],
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },

  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },

  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'employee'
      this.listTabs[0].isRendered = true
      // this.$router.push({ name: 'advise-employment-type', params: { type: 'employee' } })
    }
    this.getYear()
    this.dataFilterYear = this.yearList
    this.getComboboxCounselor()
    this.getComboboxBusiness()
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      const active = this.activeTab
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      const indexTabNotActive = this.listTabs.findIndex(x => x.key === active)
      if (active !== this.activeTab) {
        if (tab) {
          tab.isRendered = true
          this.listTabs[indexTabNotActive].isRendered = false
        }
      }
      this.$router.push({ name: 'advise-employment-type', params: { type: activeTab } }).catch(() => {})
    },
    filterYear(val) {
      this.year = val
    },

    async getComboboxCounselor() {
      const { data } = await store.comboboxCounselor()
      this.comboboxCounselor = data
    },

    async getComboboxBusiness() {
      const { data } = await store.getComboboxBusiness()
      this.comboboxBusiness = data
    },
  },
}
</script>
