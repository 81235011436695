var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-advice","size":"xl","centered":"","title":_vm.title,"cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu"},on:{"hidden":_vm.resetData,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"rule"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Người tư vấn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Người tư vấn","rules":"required","custom-messages":_vm.customCounselor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (name) { return name.id; },"label":"name","options":_vm.comboboxCounselor || [],"placeholder":"Người tư vấn"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.employeeInfo.counselorId),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "counselorId", $$v)},expression:"employeeInfo.counselorId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày tư vấn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày tư vấn","rules":"required","custom-messages":_vm.customDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"disabledInput":true},model:{value:(_vm.employeeInfo.dateAdvise),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "dateAdvise", $$v)},expression:"employeeInfo.dateAdvise"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Họ và tên")]),_c('b-form-input',{model:{value:(_vm.employeeInfo.name),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "name", $$v)},expression:"employeeInfo.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày sinh ")]),_c('date-picker',{attrs:{"disabledInput":true},model:{value:(_vm.employeeInfo.birthDay),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "birthDay", $$v)},expression:"employeeInfo.birthDay"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Địa chỉ")]),_c('b-form-input',{model:{value:(_vm.employeeInfo.address),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "address", $$v)},expression:"employeeInfo.address"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('small',[_vm._v("Giới tính")]),_c('b-form-radio-group',{staticClass:"mt-2",attrs:{"id":"radio-group-1","options":_vm.options,"name":"radio-options"},model:{value:(_vm.employeeInfo.gender),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "gender", $$v)},expression:"employeeInfo.gender"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Trình độ học vấn")]),_c('validation-provider',{attrs:{"name":"Trình độ học vấn","rules":"required","custom-messages":_vm.customLevel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (value) { return value.value; },"label":"label","options":_vm.optionsSelect || [],"placeholder":"Trình độ học vấn"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.employeeInfo.academicLevel),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "academicLevel", $$v)},expression:"employeeInfo.academicLevel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('b-form-input',{model:{value:(_vm.employeeInfo.phoneNumber),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "phoneNumber", $$v)},expression:"employeeInfo.phoneNumber"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-end",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1"},model:{value:(_vm.employeeInfo.isAdviseJob),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "isAdviseJob", $$v)},expression:"employeeInfo.isAdviseJob"}},[_vm._v(" Số người sử dụng lao động được tư vấn ")])],1)],1)],1),_c('b-form-group',{attrs:{"label":"Nội dung tư vấn","label-for":"content"}},[_c('b-form-textarea',{attrs:{"id":"content","no-resize":""},model:{value:(_vm.employeeInfo.content),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "content", $$v)},expression:"employeeInfo.content"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }