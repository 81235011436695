var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-advice-business","size":"xl","centered":"","title":_vm.title,"cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu"},on:{"ok":_vm.handleOk,"hidden":_vm.resetData}},[_c('validation-observer',{ref:"rule"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Người tư vấn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Người tư vấn","rules":"required","custom-messages":_vm.customCounselor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (name) { return name.id; },"label":"name","options":_vm.comboboxCounselor || [],"placeholder":"Người tư vấn"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.employerInfo.counselorId),callback:function ($$v) {_vm.$set(_vm.employerInfo, "counselorId", $$v)},expression:"employerInfo.counselorId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày tư vấn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày tư vấn","rules":"required","custom-messages":_vm.customDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"disabledInput":true},model:{value:(_vm.employerInfo.dateAdvise),callback:function ($$v) {_vm.$set(_vm.employerInfo, "dateAdvise", $$v)},expression:"employerInfo.dateAdvise"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Doanh nghiệp "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Doanh nghiệp","rules":"required","custom-messages":_vm.customBusiness},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (name) { return name.id; },"label":"name","options":_vm.comboboxBusiness || [],"placeholder":"Doanh nghiệp"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.employerInfo.adviseBussinessId),callback:function ($$v) {_vm.$set(_vm.employerInfo, "adviseBussinessId", $$v)},expression:"employerInfo.adviseBussinessId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Người đại diện doanh nghiệp tư vấn")]),_c('b-form-input',{model:{value:(_vm.employerInfo.presenter),callback:function ($$v) {_vm.$set(_vm.employerInfo, "presenter", $$v)},expression:"employerInfo.presenter"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('b-form-input',{model:{value:(_vm.employerInfo.phoneNumber),callback:function ($$v) {_vm.$set(_vm.employerInfo, "phoneNumber", $$v)},expression:"employerInfo.phoneNumber"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Nội dung tư vấn","label-for":"content"}},[_c('b-form-textarea',{attrs:{"id":"content","no-resize":""},model:{value:(_vm.employerInfo.content),callback:function ($$v) {_vm.$set(_vm.employerInfo, "content", $$v)},expression:"employerInfo.content"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }