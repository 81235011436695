var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm hoạt động tư vấn',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"clickDowloadSample":_vm.downloadExampleFile,"clickExportExcel":_vm.downloadExportFile,"importFile":function($event){return _vm.importFile($event)},"clickDelete":_vm.deleteItems,"clickAdd":_vm.addEmployer,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"employer-table",staticClass:"mh-60",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.getDetailEmployer(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):(props.column.field === 'dateAdvise')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.dateAdvise))+" ")]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('modal-add-advice',{attrs:{"comboboxCounselor":_vm.comboboxCounselor,"comboboxBusiness":_vm.comboboxBusiness,"data":_vm.detailEmployer,"title":_vm.modeType === 'add' ? 'Thêm hoạt động tư vấn việc làm người sử dụng lao động': 'Chỉnh sửa hoạt động tư vấn việc làm người sử dụng lao động'},on:{"update":_vm.updateEmployer}}),_c('confirm-modal',{attrs:{"id":"confirm-modal","title":'',"content":"Bạn có chắc chắn muốn xóa hoạt động tư vấn không"},on:{"accept":_vm.acceptDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }