<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm hoạt động tư vấn'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDowloadSample="downloadExampleFile"
      @clickExportExcel="downloadExportFile"
      @importFile="importFileExcel($event)"
      @clickDelete="deleteItems"
      @clickAdd="addEmployee"
      @search="search($event)"
    />
    <vue-good-table
      ref="employee-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            class="ml-2"
            @click="getDetailEmployee(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <span v-else-if="props.column.field === 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'dateAdvise'">
          {{ props.row.dateAdvise | formatDate }}
        </span>
      </template>
    </vue-good-table>
    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
    <modal-add-advice
      :comboboxCounselor="comboboxCounselor"
      :data="detailEmployee"
      :title="modeType === 'add' ? 'Thêm hoạt động tư vấn việc làm người lao động': 'Chỉnh sửa hoạt động tư vấn việc làm người lao động' "
      @update="updateEmployee"
    />
    <confirm-modal
      id="confirm-modal"
      :title="''"
      content="Bạn có chắc chắn muốn xóa hoạt động tư vấn không"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAddAdvice from './ModalAddAdvice.vue'
import store from '../../store'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    ButtonAllHeader,
    BFormGroup,
    VSelect,
    BRow,
    BCol,
    ModalAddAdvice,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    year: {
      type: Number,
      default: null,
    },
    comboboxCounselor: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      queryParams: {
        year: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Người tư vấn',
          field: 'counselor',
          sortable: false,
        },
        {
          label: 'Người được tư vấn',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Địa chỉ người được tư vấn',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Ngày tư vấn',
          field: 'dateAdvise',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          width: '175px',
          sortable: false,
        },
      ],
      dataList: [],
      totalRecord: 0,
      showBtnMultiDelete: false,
      deletedIds: [],
      detailEmployee: undefined,
      arrayExcel: [
        'counselor',
        'dateAdvise',
        'name',
        'birthDay',
        'gender',
        'address',
        'academicLevel',
        'phoneNumber',
        'content',
      ],
      modeType: '',
    }
  },
  watch: {
    year(val) {
      this.queryParams.year = val
      this.getListEmployee()
    },
  },
  created() {
    this.getListEmployee()
  },
  methods: {
    ...mapActions('employee', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcelEmployee',
    ]),
    async getListEmployee() {
      this.$showLoading()
      const { data } = await store.getListEmployee(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListEmployee()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListEmployee()
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['employee-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    async acceptDelete() {
      const res = await store.deleteEmployee(this.deletedIds)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa hoạt động tư vấn việc làm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListEmployee()
      }
    },
    async updateEmployee(info) {
      let variant = ''
      let message = ''
      if (!info.id) {
        const res = await store.addEmployee(info)
        if (res.success) {
          variant = 'success'
          message = 'Thêm hoạt động tư vấn thành công'
        } else {
          variant = 'danger'
          message = 'Thêm hoạt động tư vấn thất bại'
        }
      } else {
        const res = await store.editEmployee(info)
        if (res.success) {
          variant = 'success'
          message = 'Chỉnh sửa hoạt động tư vấn thành công'
        } else {
          variant = 'danger'
          message = 'Chỉnh sửa hoạt động tư vấn thất bại'
        }
      }
      this.getListEmployee()
      this.$root.$bvToast.toast(message, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
    },
    async getDetailEmployee(id) {
      this.modeType = 'edit'
      const res = await store.getDetailEmployee(id)
      this.detailEmployee = res
      this.$bvModal.show('modal-add-advice')
    },
    addEmployee() {
      this.detailEmployee = undefined
      this.modeType = 'add'
      this.$bvModal.show('modal-add-advice')
    },

    // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValiData(event)
      }
    },

    async getValiData(dataInput) {
      const datafile = dataInput.map(element => ({
        ...element,
        dateAdvise: Date.parse(element.dateAdvise) ? `${new Date(element.dateAdvise).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
        birthDay: Date.parse(element.birthDay) ? `${new Date(element.birthDay).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
      }))
      const model = {
        listExcel: datafile,
        isValidate: true,
      }

      await this.getApiExcelEmployee(model)
      this.$router.push({ name: 'advise-employment-import-employee' })
    },

  },
}
</script>
