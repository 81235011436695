<template>
  <b-modal
    id="modal-add-advice-business"
    size="xl"
    centered
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleOk"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Người tư vấn <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Người tư vấn"
              rules="required"
              :custom-messages="customCounselor"
            >
              <v-select
                v-model="employerInfo.counselorId"
                :reduce="(name) => name.id"
                :class="{'is-invalid':errors.length > 0 }"
                label="name"
                :options="comboboxCounselor || []"
                placeholder="Người tư vấn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày tư vấn <span class="text-danger">(*)</span> </label>
            <validation-provider
              #default="{ errors }"
              name="Ngày tư vấn"
              rules="required"
              :custom-messages="customDate"
            >
              <date-picker
                v-model="employerInfo.dateAdvise"
                :disabledInput="true"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Doanh nghiệp <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Doanh nghiệp"
              rules="required"
              :custom-messages="customBusiness"
            >
              <v-select
                v-model="employerInfo.adviseBussinessId"
                :reduce="(name) => name.id"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="comboboxBusiness || []"
                placeholder="Doanh nghiệp"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Người đại diện doanh nghiệp tư vấn</label>
            <b-form-input
              v-model="employerInfo.presenter"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input v-model="employerInfo.phoneNumber" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        label="Nội dung tư vấn"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="employerInfo.content"
          no-resize
        />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    vSelect,
    BFormInput,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          counselorId: null,
          dateAdvise: null,
          adviseBussinessId: null,
          presenter: null,
          phoneNumber: null,
          content: null,
        }
      },
    },
    comboboxCounselor: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    comboboxBusiness: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      employerInfo: JSON.parse(JSON.stringify(this.data)),
      customCounselor: {
        required: 'Người tư vấn là bắt buộc',
      },
      customDate: {
        required: 'Ngày tư vấn là bắt buộc',
      },
      customBusiness: {
        required: 'Doanh nghiệp là bắt buộc',
      },
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.employerInfo = JSON.parse(JSON.stringify(val))
      },
    },
  },
  methods: {
    resetData() {
      this.employerInfo = JSON.parse(JSON.stringify(this.data))
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$bvModal.hide('modal-add-advice-business')
          this.$emit('update', this.employerInfo)
        }
      })
    },
  },
}
</script>
