import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getListEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.get('/AdviseJobWorker/adviseJobWorkers', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async comboboxCounselor() {
    try {
      const { data } = await axiosApiInstance.get('/Worker/get-combobox-worker')
      return data
    } catch (err) {
      return err
    }
  },
  async addEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.post('/AdviseJobWorker/adviseJobWorkers', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getDetailEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/AdviseJobWorker/adviseJobWorkers/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async editEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.put('/AdviseJobWorker/adviseJobWorkers', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.post('/AdviseJobWorker/delete-advise-job-workers', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getListEmployer(payload) {
    try {
      const { data } = await axiosApiInstance.get('/AdviseJobBussiness/adviseJobBussinesses', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },

  async getDetailEmployer(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/AdviseJobBussiness/adviseJobBussinesses/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async getComboboxBusiness() {
    try {
      const { data } = await axiosApiInstance.get('/Bussiness/get-combobox-bussiness')
      return data
    } catch (err) {
      return err
    }
  },
  async addEmployer(payload) {
    try {
      const { data } = await axiosApiInstance.post('/AdviseJobBussiness/adviseJobBussinesses', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async editEmployer(payload) {
    try {
      const { data } = await axiosApiInstance.put('/AdviseJobBussiness/adviseJobBussinesses', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteEmployer(payload) {
    try {
      const { data } = await axiosApiInstance.post('/AdviseJobBussiness/delete-advise-job-bussinesses', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
